import React from "react"

const WhyUs = () => (
    <div id="why" className="section dark" >
        <div className="container">
            <div className="row sectonTitle">
                <div className="col"><h2>Why Choose Us</h2></div>
            </div>
            <div className="row sectonContent">
                <div className="col-lg-4">
                    <div className="Row"><div className="col"><h4>Personalized Service</h4></div></div>
                    <div className="Row"><div className="col">
                        <p className="sectionText">
                            As a customer you will have full access to an experienced Web Developer to answer your questions and help you find the right solution. 
                            We will learn about your unique needs and do the hard work for you.
                        </p>
                    </div></div>
                </div>
                <div className="col-lg-4">
                    <div className="Row"><div className="col"><h4>Limitless Customization</h4></div></div>
                    <div className="Row"><div className="col">
                        <p className="sectionText">
                            Unlike our competitors, we offer more than just cookie cutter self-service templates. 
                            Whether your goal is to create a completely unique online identity or just add a new feature to an existing site. 
                            Our team is always here to help your business get the competitive advantage it needs.
                        </p>
                    </div></div>
                </div>
                <div className="col-lg-4">
                    <div className="Row"><div className="col"><h4>No Commitments</h4></div></div>
                    <div className="Row"><div className="col">
                        <p className="sectionText">
                            Everyday businesses use popular website builders to get online while unknowingly committing themselves to a platform and its limitations. 
                            With us you have complete ownership of your website source code. This means you can switch platforms at any time and take your site with you.  
                        </p>
                    </div></div>
                </div>
            </div>
        </div>   
    </div> 
)
  
  export default WhyUs