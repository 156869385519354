import React from "react"
import Layout from "../components/layout"
import ContactUs from '../components/contact'
import WhyUs from '../components/why'
import SEO from "../components/seo"

const AboutMe = () => (
  <Layout page="about" >
    <SEO title="Rethink Main - About Us" />
    <WhyUs />
    <ContactUs />
  </Layout>
)

export default AboutMe